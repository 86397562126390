<template>
  <svg
    class="barchart"
    :width="width + marginLeft + marginRight"
    :height="height + marginTop"
  >
    <g :transform="`translate(${marginLeft / 2}, ${marginTop / 2})`">
      <g
        class="x-axis"
        fill="none"
        font-size="12"
        :transform="`translate(30, ${height})`"
        style="color: #888"
      >
        <text
          :x="width / 2"
          :y="marginBottom / 2 - 10"
          fill="gray"
          text-anchor="middle"
        >
          Numero de identificación de los edificios
        </text>
        <path
          class="domain"
          stroke="currentColor"
          :d="`M0.5,6V0.5H${width}.5V6`"
        ></path>
        <g
          class="tick"
          opacity="1"
          font-size="10"
          font-family="sans-serif"
          text-anchor="middle"
          v-for="(bar, index) in bars"
          :key="index"
          :transform="`translate(${distributed * index + 15}, 0)`"
        >
          <line stroke="currentColor" y2="6"></line>
          <text fill="currentColor" y="9" dy="0.71em">{{ bar.xLabel }}</text>
        </g>
      </g>
      <g
        class="y-axis"
        fill="none"
        x="200"
        font-size="12"
        :transform="`translate(30, 0)`"
        style="color: #888"
      >
        <text
          :x="-height / 2"
          :y="-marginLeft / 2 + 10"
          fill="gray"
          transform="rotate(-90)"
          text-anchor="middle"
        >
          {{ yLabel }}
        </text>
        <path
          class="domain"
          stroke="currentColor"
          :d="`M0.5,${height}.5H0.5V0.5H-6`"
        ></path>
        <g
          class="tick"
          opacity="1"
          font-size="10"
          font-family="sans-serif"
          text-anchor="end"
          v-for="(tick, index) in yTicks"
          :key="index"
          :transform="`translate(0, ${y(tick) + 1})`"
        >
          <line stroke="currentColor" x2="-3"></line>
          <text fill="currentColor" x="-4" dy="0.32em">{{ tick }}</text>
        </g>
      </g>
      <g class="bars" fill="none" :transform="`translate(40, 0)`">
        <rect
          v-for="(bar, index) in bars"
          :fill="bar.color"
          :key="index"
          :height="bar.height"
          :width="bar.width"
          :x="distributed * index"
          :y="bar.y"
        ></rect>
      </g>
      <g class="line">
        <line
          x1="30"
          :y1="`${y(averageValue)}`"
          :x2="width"
          :y2="`${y(averageValue)}`"
          style="stroke:rgb(255,0,0);stroke-width:2"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { scaleLinear, scaleBand } from "d3-scale";

export default {
  name: "BarChart",
  props: {
    height: { default: 200 },
    width: { default: 500 },
    dataSet: { default: [] },
    marginLeft: { default: 40 },
    marginTop: { default: 40 },
    marginBottom: { default: 40 },
    marginRight: { default: 40 },
    tickCount: { default: 5 },
    barPadding: { default: 30 },
    averageValue: { default: 0 },
    yLabel: { default: "" },
  },
  computed: {
    distributed() {
      const space = this.width / this.dataSet.length;
      return space;
    },
    yTicks() {
      return this.y.ticks(this.tickCount);
    },
    x() {
      return scaleBand()
        .range([0, this.width])
        .domain(this.dataSet.map((e) => e));
    },
    y() {
      let values = this.dataSet.map((e) => e[1]);
      return scaleLinear()
        .range([this.height, 0])
        .domain([0, Math.max(...values)]);
    },
    bars() {
      let bars = this.dataSet.map((d) => {
        let height = this.height - this.y(d[1]);
        if (d[1] === 0) {
          height = 0;
        }

        return {
          xLabel: d[0].toString(),
          x: this.distributed,
          y: this.y(d[1]),
          color: d[1] > this.averageValue ? "red" : "green",
          width: 10,
          height: height,
        };
      });

      return bars;
    },

    lineY() {
      return this.y(30);
    },
  },
};
</script>

<style lang="scss"></style>
